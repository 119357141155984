export const useSustainabilityStore = defineStore('sustainabilityStore', () => {
  const showSustainabilityPopup = ref(false);
  const sustainability = ref(null);

  // GETTERS
  const isSustainabilityPopupOpen = computed(() => {
    return showSustainabilityPopup.value;
  });

  const getCurrentSustainabilityDetails = computed(() => {
    return sustainability.value;
  });

  // ACTIONS
  const setSustainabilityDetails = (newSustainabilityDetails) => {
    sustainability.value = newSustainabilityDetails;
    showSustainabilityPopup.value = !!newSustainabilityDetails?.tier;
  };

  const toggleSustainabilityPopup = (isVisible) => {
    showSustainabilityPopup.value = isVisible;
  };

  return {
    setSustainabilityDetails,
    toggleSustainabilityPopup,
    getCurrentSustainabilityDetails,
    isSustainabilityPopupOpen,
  };
});
